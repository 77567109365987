<template>
  <div>
    <Table2>
      <tbody>
        <tr
          class="odd"
          v-for="familyMember in familyMembers"
          :key="familyMember._id"
        >
          <td style="display: flex">
            <Avatar :size="40" :src="getAvatarUrl(familyMember._id)"></Avatar>
            <div style="margin-left: 8px; display: grid">
              <Body1 style="font-weight: 400"
                >{{ familyMember.firstName }} {{ familyMember.lastName }}</Body1
              >
              <Subtitle1
                >{{ computeAge(familyMember.birthDate) }} ans</Subtitle1
              >
            </div>
            <Icon
              style="align-self: center; margin-left: auto"
              @click="setAdminWaitingConfirmation(familyMember)"
              size="medium"
              name="plus"
              color="var(--kermony-office-rose)"
            />
          </td>
        </tr>
      </tbody>
    </Table2>
    <Button
      style="width: 100%; margin-top: 18px"
      size="medium"
      theme="primary"
      icon-left="plus"
      @click="addFamilyMember()"
    >
      AJOUTER UN MEMBRE
    </Button>
    <a-modal
      v-model:visible="modalConfirmationVisible"
      title="Donner accès à l'application"
      @ok="handleOk"
      okText="Confirmer"
      cancelText="Annuler"
    >
      <div v-if="setAdminWaitingConfirmationUser !== null">
        <p>
          En poursuivant
          {{ setAdminWaitingConfirmationUser.firstName }}
          {{ setAdminWaitingConfirmationUser.lastName }} aura accès aux données
          financières de votre famille. Un email sera adressé à
          <strong>{{ setAdminWaitingConfirmationUser.email }}</strong>
          afin de configurer un mot de passe sur son compte.
        </p>
      </div>
    </a-modal>

    <a-modal
      v-model:visible="modalMissingEmail"
      title="Email manquant !"
      @ok="closeModalMissingEmail()"
      okText="Fermer"
      :cancelButtonProps="{ style: { display: 'none' } }"
    >
      <div v-if="setAdminWaitingConfirmationUser !== null">
        <p>
          {{ setAdminWaitingConfirmationUser.firstName }}
          {{ setAdminWaitingConfirmationUser.lastName }} ne peut pas avoir de
          compte car aucune adresse email n'est renseignée. Veuillez contacter
          l'équipe Kermony
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";

import FamilyApi from "@/api/family";
import CustomerApi from "@/api/customer";

import router from "@/router";
import moment from "moment-timezone";
import { map } from "lodash";
import Avatar from "@/components/Avatars/Avatar";
import Body1 from "@/components/Texts/Body1";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Table2 from "@/components/Tables/Table2";
import { getAvatarUrl } from "@/utils/avatar";
import Button from "@/components/Buttons/Button.vue";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import Icon from "@/components/Icons/Icon.vue";

export default {
  name: "ViewMobile",
  components: {
    Icon,
    Button,
    Table2,
    Subtitle1,
    Body1,
    Avatar,
  },
  data() {
    return {
      familyMembers: [],
      finalData: {},
      form: {
        user: {},
        family: {},
        matrimonial: {},
        custom: {
          isAdopted: "non",
        },
      },
      currentStep: 1,
    };
  },

  setup() {
    const modalConfirmationVisible = ref(false);

    const showModalConfirmationVisible = () => {
      modalConfirmationVisible.value = true;
    };

    const modalMissingEmail = ref(false);
    const setAdminWaitingConfirmationUser = ref(null);

    const showModalMissingEmail = (user) => {
      setAdminWaitingConfirmationUser.value = user;
      modalMissingEmail.value = true;
    };
    const closeModalMissingEmail = () => {
      modalMissingEmail.value = false;
    };

    const setAdminWaitingConfirmation = (user) => {
      if (!user.email) {
        showModalMissingEmail(user);
        return;
      }

      setAdminWaitingConfirmationUser.value = user;
      showModalConfirmationVisible();
    };
    const closeModalConfirmation = () => {
      modalConfirmationVisible.value = false;
    };

    return {
      v$: useVuelidate(),
      showModalConfirmationVisible,
      modalConfirmationVisible,
      showModalMissingEmail,
      modalMissingEmail,
      setAdminWaitingConfirmation,
      setAdminWaitingConfirmationUser,
      closeModalConfirmation,
      closeModalMissingEmail,
    };
  },

  created() {
    this.fetchFamilyMembers();
  },

  validations() {
    return {
      familyMembers: [],
    };
  },

  methods: {
    handleOk() {
      this.setAdmin(this.setAdminWaitingConfirmationUser._id);
      this.closeModalConfirmation();
    },
    addFamilyMember() {
      FamilyApi.createFamilyMember()
        .then(() => {
          alert(
            "La demande à bien été envoyée, l'équipe Kermony reviendra vers vous rapidement."
          );
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[addFamilyMember]",
              "Error from API:",
              error.response.data.message
            );
        });
    },
    getAvatarUrl,
    formatDate(d) {
      return moment(d).format("DD/MM/YYYY");
    },
    computeAge(d) {
      return moment().diff(d, "years");
    },
    getNameWithUserId(userId) {
      const index = this.familyMembers.findIndex((link) => link._id === userId);

      if (index !== -1)
        return `${this.familyMembers[index].firstName} ${this.familyMembers[index].lastName}`;

      return false;
    },

    displayRegimeMatrimonial() {
      if (this.form && this.form.family.linkType === "partner") return true;

      return false;
    },

    getCustomWording(value) {
      if (value === true) return "Oui";

      return "Non";
    },

    areParentsSelectable() {
      if (!this.form.family.linkedTo) return true;

      if (this.form.family.linkedTo.length > 1) return false;

      return true;
    },

    getFamilyTypeWording(stepName) {
      switch (stepName) {
        case "children":
          return "l'enfant";
        case "parent":
          return "le parent";
        case "conjoint":
          return "le conjoint ou partenaire";
      }
    },

    isActiveMenu(stepName) {
      return { "wizard-step-active": stepName === this.currentStep };
    },

    isCurrentStep(stepName) {
      return stepName === this.currentStep;
    },

    isMariage() {
      if (this.form && this.form.matrimonial.type === "mariage") return true;

      return false;
    },

    isPACS() {
      if (this.form && this.form.matrimonial.type === "pacs") return true;

      return false;
    },

    previousStep() {
      this.currentStep--;
      this.form = this.finalData;

      if (this.currentStep < 1) {
        this.currentStep = 1;
        return router.push({ name: "family.view" });
      }
    },

    setAdmin(id) {
      CustomerApi.setAdmin(id).then(() => {
        this.familyMembers = map(this.familyMembers, (familyMember) => {
          if (familyMember._id === id) {
            return { ...familyMember, isAllowedToConnect: true };
          }

          return familyMember;
        });
      });
    },

    fetchFamilyMembers() {
      FamilyApi.getFamilyMembers()
        .then((data) => {
          this.familyMembers = data.familyMembers;

          for (let i = 0; i < this.familyMembers.length; i++)
            this.familyMembers[i].fullName = this.getNameWithUserId(
              this.familyMembers[i]._id
            );
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[getFamilyMembers]",
              "Error from API:",
              error.response.data.message
            );
        });
    },
  },
};
</script>

<style scoped>
.k-table-2 tbody tr {
  border-radius: 5px;
}
</style>
