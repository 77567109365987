<template>
  <Workspace v-if="isMobile"><ViewMobile /></Workspace>
  <WorkspaceTabs v-else noPadding backgroundColor="rgba(207, 210, 212, 0.3)">
    <a-tabs animated class="big-header">
      <a-tab-pane key="1" tab="Organigramme">
        <div class="tab-family-container">
          <Tree />
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Vue en Liste">
        <div class="tab-family-container"><List /></div
      ></a-tab-pane>
    </a-tabs>
  </WorkspaceTabs>
</template>

<script>
import WorkspaceTabs from "@/components/Workspaces/WorkspaceTabs";
import List from "./List";
import Tree from "./Tree";
import { isMobile } from "@/utils/isMobile";
import Workspace from "@/components/Workspaces/Workspace";
import ViewMobile from "@/views/App/Family/ViewMobile";

export default {
  name: "tree",
  components: { ViewMobile, Workspace, WorkspaceTabs, List, Tree },
  data() {
    const _isMobile = isMobile();
    return {
      globalAssets: null,
      isMobile: _isMobile,
    };
  },

  mounted() {
    this.$store.commit("updateHeader", {
      title: "Ma famille",
      subtitle: "La famille, c'est sacré !",
    });
  },
};
</script>

<style scoped>
.tab-family-container {
  margin: -16px;
  padding: 16px;
}
</style>
