<template>
  <div class="workspace" :style="{ backgroundColor: backgroundColor }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WorkspaceTabs",
  props: ["backgroundColor"],
};
</script>

<style scoped>
.workspace {
  background: var(--kermony-blanc);
  border-radius: 5px;
  min-height: calc(100% - 25px);
  margin-bottom: 25px;
  position: relative;
}
</style>
