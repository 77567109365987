<template>
  <div>
    <!--    <RightTabContainer class="aligner">-->
    <!--      <Button size="medium" theme="primary" icon-left="users"-->
    <!--        >Gérer la famille</Button-->
    <!--      >-->
    <!--    </RightTabContainer>-->

    <div class="card-body">
      <div id="tree" ref="tree" style=""></div>

      <div class="zoom-buttons">
        <IconMinus
          @click="zoomOut"
          stroke="var(--kermony-office-rose)"
          style="stroke-width: 3px"
        ></IconMinus>
        <span @click="resetZoom" role="button" class="zoom-level"
          >{{ Math.trunc(scale * 100) }}%</span
        >
        <IconPlus
          @click="zoomIn"
          stroke="var(--kermony-office-rose)"
          style="stroke-width: 3px"
        ></IconPlus>
      </div>
    </div>
  </div>
</template>

<script>
import FamilyApi from "@/api/family";
import FamilyTree from "@/libs/balkangraph/familytree.js";
import { getAvatarUrl } from "@/utils/avatar";
import IconMinus from "@/components/Icons/Thin/IconMinus.vue";
import IconPlus from "@/components/Icons/Thin/IconPlus.vue";
import _ from "lodash";

export default {
  name: "Tree",
  components: { IconPlus, IconMinus },
  data() {
    return {
      zoomLevel: 1,
      scale: 1,
    };
  },
  methods: {
    zoomIn() {
      if (this.zoomLevel < 1.9) {
        this.zoomLevel += 0.1;
        this.chart.zoom(true, null, true);
      }
    },
    zoomOut() {
      if (this.zoomLevel > 0.2) {
        this.zoomLevel -= 0.1;
        this.chart.zoom(false, null, true);
      }
    },
    getFamilyData() {
      FamilyApi.getFamilyData()
        .then(async (data) => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());
          const enableDebug = _.has(params, "debug");

          // Empty input
          this.family = data.family;

          const avatars = await Promise.all(
            this.family.map((item) => getAvatarUrl(item.customer._id))
          );

          this.family = this.family.map((item, index) => {
            let tags = [];

            if (item.customer.deathDate !== null) {
              tags.push("death");
            }

            return {
              pids: item.pids,
              divorced: item.divorced,
              partners: item.partners,
              spouses: item.spouses,
              mid: item.mid,
              fid: item.fid,
              id: item.id,
              name: item.customer.firstName,
              title:
                item.customer.lastName +
                (enableDebug ? ` gen=${item.generation} id=${item.id}` : ""),
              //img: `https://eu.ui-avatars.com/api/?name=${item.customer.firstName}&length=1&background=90c0e3&color=FFF`,
              //img: `https://eu.ui-avatars.com/api/?name=${item.customer.firstName}&length=1`,
              img: avatars[index],
              tags,
            };
          });

          this.oc(this.$refs.tree, this.family);
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[getFamilyData]",
              "Error from API:",
              error.response.data.message
            );
        });
    },

    oc: function (domEl, x) {
      FamilyTree.templates.myTemplate = Object.assign(
        {},
        FamilyTree.templates.tommy
      );
      //
      // OrgChart.templates.family_template = Object.assign(
      //   {},
      //   OrgChart.templates.ana
      // );
      FamilyTree.templates.myTemplate.size = [115, 148 /* + 60 */];
      FamilyTree.templates.myTemplate.plus = "";
      FamilyTree.templates.myTemplate.minus = "";
      FamilyTree.templates.myTemplate.rippleRadius = 40;
      FamilyTree.templates.myTemplate.name =
        '<text style="fill: #6D2D68;\n' +
        "    font-family: 'Weissenhof Grotesk';\n" +
        "    font-style: normal;\n" +
        "    font-weight: 400;\n" +
        '    font-size: 18px;" fill="#000000" x="57" y="105" text-anchor="middle">{val}</text>';
      FamilyTree.templates.myTemplate.title =
        '<text style="font-size: 10px;" fill="#aeaeae" x="57" y="120" text-anchor="middle">{val}</text>';
      FamilyTree.templates.myTemplate.img =
        '<clipPath id="{randId}"><rect x="25" y="16" width="65" height="65" rx="5"/></clipPath></circle><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="25" y="16"  width="65" height="65"></image>';
      FamilyTree.templates.myTemplate.node =
        '<circle stroke-width="3" fill="none" stroke="#aeaeae" cx="43" cy="43" r="41.5"></circle>';
      FamilyTree.templates.myTemplate.defs =
        '<g transform="matrix(0.05,0,0,0.05,-13,-15.5)" id="baby"><circle cx="260" cy="310" r="200" fill="#ffffff"></circle><path fill="#aeaeae" d="m468.64 268.32h-13.591c-18.432-89.348-95.612-157.432-189.139-161.798-.501-.185-1.015-.348-1.545-.482-18.363-4.622-31.188-22.595-31.188-43.707 0-17.828 14.468-32.333 32.252-32.333 12.573 0 22.802 10.258 22.802 22.866 0 8.284 6.716 15 15 15s15-6.716 15-15c0-29.15-23.687-52.866-52.802-52.866-34.326 0-62.252 27.962-62.252 62.333 0 17.876 5.828 34.443 15.769 47.432-80.698 15.127-144.725 78.25-161.291 158.555h-13.591c-24.103 0-43.712 19.596-43.712 43.683 0 24.086 19.609 43.682 43.712 43.682h14.692c20.935 89.871 101.582 157.018 197.596 157.018s176.66-67.148 197.596-157.018h14.692c24.103 0 43.712-19.596 43.712-43.682 0-24.087-19.609-43.683-43.712-43.683zm-265.054 55.257c-8.284-.024-14.981-6.758-14.958-15.043.007-2.337-.708-13.999-15.481-14.041-.026 0-.053 0-.08 0-14.697 0-15.475 11.62-15.481 13.953-.023 8.284-6.75 15.007-15.043 14.957-8.284-.024-14.98-6.759-14.957-15.043.038-13.322 5.349-25.101 14.955-33.166 8.223-6.904 19.065-10.702 30.543-10.702h.148c11.534.033 22.412 3.896 30.63 10.876 9.559 8.12 14.803 19.928 14.765 33.25-.023 8.27-6.735 14.957-14.999 14.957-.013.002-.027.002-.042.002zm52.766 129.374c-26.485 0-48.033-21.533-48.033-48.002 0-8.284 6.716-15 15-15s15 6.716 15 15c0 9.926 8.089 18.002 18.033 18.002s18.033-8.076 18.033-18.002c0-8.284 6.716-15 15-15s15 6.716 15 15c-.001 26.469-21.548 48.002-48.033 48.002zm113.765-129.374c-.015 0-.029 0-.044 0-8.284-.024-14.98-6.759-14.957-15.043.016-5.445-1.993-9.263-6.14-11.673-5.407-3.142-13.27-3.165-18.695-.053-4.161 2.387-6.191 6.193-6.207 11.638-.023 8.27-6.735 14.957-14.999 14.957-.015 0-.029 0-.043 0-8.284-.024-14.981-6.758-14.958-15.043.046-16.149 7.802-29.845 21.281-37.576 14.814-8.497 33.929-8.443 48.695.138 13.434 7.807 21.112 21.547 21.066 37.696-.023 8.271-6.735 14.959-14.999 14.959z"/> </g>';

      FamilyTree.templates.myTemplate.node =
        '<rect height="148" width="115" y="" x="" rx="5" stroke="#000000" fill="#FFFFFF" stroke-width="0" style="filter: drop-shadow(0px 2px 12px rgb(33 36 78 / 10%));"></rect>';

      FamilyTree.templates.myTemplate.link =
        '<path stroke-linejoin="round" stroke="rgba(207, 210, 212, 1)" stroke-width="3px" fill="none" d="{rounded}"  />';

      FamilyTree.templates.myTemplate_death = Object.assign(
        {},
        FamilyTree.templates.myTemplate
      );

      FamilyTree.templates.myTemplate_death.node =
        '<rect height="148" width="115" y="" x="" rx="5" stroke="#000000" fill="#CFD2D4" stroke-width="0" style="filter: drop-shadow(0px 2px 12px rgb(33 36 78 / 10%));"></rect>';

      /*
      OrgChart.templates.family_template_blue.minus =
        '<path d="M9 16H23" stroke="#AC2369" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" style="transform: translateY(-30px);"/>\n' +
        '<rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#AC2369" fill="transparent" style="transform: translateY(-30px);"/>';

      OrgChart.templates.family_template_blue.plus =
        '<path d="M16 8V24M8 16H24" stroke="#AC2369" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" style="transform: translateY(-30px);"/>\n' +
        '<rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#AC2369" fill="transparent" style="transform: translateY(-30px);"/>';


       */
      FamilyTree.LINK_ROUNDED_CORNERS = 15;
      // OrgChart.action.zoom = function (e) {
      //   e.preventDefault();
      // };

      this.chart = new FamilyTree(domEl, {
        tags: {
          death: {
            template: "myTemplate_death",
          },
        },

        // scaleInitial: 1.2,
        template: "myTemplate",
        enableSearch: false,
        //template: "family_template",
        // mouseScrool: OrgChart.action.zoom,
        //scaleInitial: OrgChart.match.boundary,
        //mouseScroll: OrgChart.action.xScroll,
        // enableSearch: false,
        // siblingSeparation: 100,
        nodes: x,
        nodeBinding: {
          field_0: "name",
          name: "name",
          title: "title",
          img: "img",
        },
        /*
        tags: {
          blue: {
            template: "family_template_blue",
          },
        },

         */
      });

      /*
      OrgChart.templates.family_template = Object.assign(
        {},
        OrgChart.templates.ana
      );
      OrgChart.templates.family_template.size = [86, 86];
      OrgChart.templates.family_template.plus = "";
      OrgChart.templates.family_template.minus = "";
      OrgChart.templates.family_template.rippleRadius = 40;
      OrgChart.templates.family_template.name =
        '<text style="font-size: 12px;" fill="#000000" x="43" y="100" text-anchor="middle">{val}</text>';
      OrgChart.templates.family_template.title =
        '<text style="font-size: 12px;" fill="#aeaeae" x="43" y="115" text-anchor="middle">{val}</text>';
      OrgChart.templates.family_template.img =
        '<clipPath id="{randId}"><circle cx="43" cy="43" r="38.5"></circle></clipPath></circle><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="3" y="3"  width="80" height="80"></image>';
      OrgChart.templates.family_template.node =
        '<circle stroke-width="3" fill="none" stroke="#aeaeae" cx="43" cy="43" r="41.5"></circle>';
      OrgChart.templates.family_template.defs =
        '<g transform="matrix(0.05,0,0,0.05,-13,-15.5)" id="baby"><circle cx="260" cy="310" r="200" fill="#ffffff"></circle><path fill="#aeaeae" d="m468.64 268.32h-13.591c-18.432-89.348-95.612-157.432-189.139-161.798-.501-.185-1.015-.348-1.545-.482-18.363-4.622-31.188-22.595-31.188-43.707 0-17.828 14.468-32.333 32.252-32.333 12.573 0 22.802 10.258 22.802 22.866 0 8.284 6.716 15 15 15s15-6.716 15-15c0-29.15-23.687-52.866-52.802-52.866-34.326 0-62.252 27.962-62.252 62.333 0 17.876 5.828 34.443 15.769 47.432-80.698 15.127-144.725 78.25-161.291 158.555h-13.591c-24.103 0-43.712 19.596-43.712 43.683 0 24.086 19.609 43.682 43.712 43.682h14.692c20.935 89.871 101.582 157.018 197.596 157.018s176.66-67.148 197.596-157.018h14.692c24.103 0 43.712-19.596 43.712-43.682 0-24.087-19.609-43.683-43.712-43.683zm-265.054 55.257c-8.284-.024-14.981-6.758-14.958-15.043.007-2.337-.708-13.999-15.481-14.041-.026 0-.053 0-.08 0-14.697 0-15.475 11.62-15.481 13.953-.023 8.284-6.75 15.007-15.043 14.957-8.284-.024-14.98-6.759-14.957-15.043.038-13.322 5.349-25.101 14.955-33.166 8.223-6.904 19.065-10.702 30.543-10.702h.148c11.534.033 22.412 3.896 30.63 10.876 9.559 8.12 14.803 19.928 14.765 33.25-.023 8.27-6.735 14.957-14.999 14.957-.013.002-.027.002-.042.002zm52.766 129.374c-26.485 0-48.033-21.533-48.033-48.002 0-8.284 6.716-15 15-15s15 6.716 15 15c0 9.926 8.089 18.002 18.033 18.002s18.033-8.076 18.033-18.002c0-8.284 6.716-15 15-15s15 6.716 15 15c-.001 26.469-21.548 48.002-48.033 48.002zm113.765-129.374c-.015 0-.029 0-.044 0-8.284-.024-14.98-6.759-14.957-15.043.016-5.445-1.993-9.263-6.14-11.673-5.407-3.142-13.27-3.165-18.695-.053-4.161 2.387-6.191 6.193-6.207 11.638-.023 8.27-6.735 14.957-14.999 14.957-.015 0-.029 0-.043 0-8.284-.024-14.981-6.758-14.958-15.043.046-16.149 7.802-29.845 21.281-37.576 14.814-8.497 33.929-8.443 48.695.138 13.434 7.807 21.112 21.547 21.066 37.696-.023 8.271-6.735 14.959-14.999 14.959z"/> </g>';
      OrgChart.templates.family_template_blue = Object.assign(
        {},
        OrgChart.templates.family_template
      );
      OrgChart.templates.family_template_blue.node =
        '<circle stroke-width="3" fill="none" stroke="#ff9b20" cx="43" cy="43" r="41.5"></circle>';

      OrgChart.action.zoom = function (e) {
        e.preventDefault();
      };
      // mouseScrool: OrgChart.action.zoom,

      this.chart = new OrgChart(domEl, {
        // scaleInitial: 1.2,
        template: "family_template",
        // mouseScrool: OrgChart.action.zoom,
        scaleInitial: OrgChart.match.boundary,
        mouseScrool: OrgChart.action.xScroll,
        enableSearch: false,
        siblingSeparation: 100,
        nodes: x,
        nodeBinding: {
          field_0: "id",
          name: "name",
          title: "title",
          img: "img",
        },
        tags: {
          blue: {
            template: "family_template_blue",
          },
        },
      });

       */

      this.chart.on("render-link", (sender, args) => {
        var cnodeData = this.chart.get(args.cnode.id);
        var nodeData = this.chart.get(args.node.id);

        if (
          cnodeData.spouses !== undefined &&
          nodeData.spouses !== undefined &&
          cnodeData.spouses.includes(args.node.id) &&
          nodeData.spouses.includes(args.cnode.id)
        ) {
          return;
        }

        if (
          cnodeData.divorced !== undefined &&
          nodeData.divorced !== undefined &&
          cnodeData.divorced.includes(args.node.id) &&
          nodeData.divorced.includes(args.cnode.id)
        ) {
          args.html = args.html.replace("path", "path stroke-dasharray='3, 2'");
        }

        if (
          cnodeData.partners !== undefined &&
          nodeData.partners !== undefined &&
          cnodeData.partners.includes(args.node.id) &&
          nodeData.partners.includes(args.cnode.id)
        ) {
          args.html = args.html.replace("path", "path stroke-dasharray='3, 2'");
        }
      });

      // Hide default popup
      this.chart.on("click", function () {
        return false; //to cansel the click event
      });
    },
  },
  mounted() {
    this.getFamilyData();

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.chart) {
        this.scale = this.chart.getScale();
      }
    }, 100);
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style>
#tree {
  background-color: transparent !important;
  background: transparent !important;
}

.zoom-buttons {
  z-index: 1;
  background-color: #eae8ea;
  padding: 3px 8px;
  border-radius: 2px;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.zoom-buttons > * {
  cursor: pointer;
}

.zoom-level {
  /*color: var(--kermony-office-bleu-1);*/
  color: var(--kermony-office-rose);
  margin-left: 6px;
  margin-right: 6px;
}

#tree > svg {
  background-color: transparent !important;
}
[text-anchor="start"] {
  user-select: none;
  fill: transparent;
}

.bft-fill {
  display: none;
}

.node.death {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  background: #cfd2d4;
}
</style>
